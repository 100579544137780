import type { Epic } from 'behavior/types';
import type { ProductSetAction } from './actions';
import type { CalculatedProductData, ProductData } from './types';
import { areSettingsLoaded } from 'behavior/settings';
import { groupBy, map, mergeMap, switchMap, takeUntil, pluck, first } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { PRODUCT_SET_REQUESTED, productSetReceived } from './actions';
import { LOCATION_CHANGED } from 'behavior/events';
import { RELOAD_LOCATION } from 'behavior/routing';
import { productsQuery, loadCalculatedFieldsQuery } from './queries';

type ProductSet = {
  products: {
    products: Array<ProductData | CalculatedProductData>;
  };
};

type ProductSetResponse = {
  catalog: {
    productSet: ProductSet | null;
  };
};

const productSetsEpic: Epic<ProductSetAction> = (action$, state$, { api }) => {
  const reset$ = action$.pipe(ofType(LOCATION_CHANGED, RELOAD_LOCATION));

  return action$.pipe(
    ofType(PRODUCT_SET_REQUESTED),
    groupBy(action => action.payload.contentBlockId, action => action.payload),
    mergeMap(group => group.pipe(
      switchMap(({ contentBlockId, productSetId, size, sorting, calculate }) => state$.pipe(
        pluck('settings'),
        first(areSettingsLoaded),
        switchMap(settings => {
          const productOptions = {
            isProductGroupingEnabled: settings.product.productGrouping.isEnabled,
          };
          const variables = {
            id: productSetId,
            options: {
              page: { index: 0, size },
              sorting,
            },
            loadCategories: state$.value.analytics && state$.value.analytics.isTrackingEnabled,
          };
          const query = calculate ? loadCalculatedFieldsQuery : productsQuery(productOptions);

          return api.graphApi<ProductSetResponse>(query, variables).pipe(
            pluck('catalog', 'productSet'),
            map(productSet => productSetReceived(contentBlockId, getProductSetProducts(productSet), calculate)),
            takeUntil(reset$),
          );
        }),
      )),
    )),
  );
};

export default productSetsEpic;

const getProductSetProducts = (productSet: ProductSet | null): Array<ProductData | CalculatedProductData> => {
  if (productSet)
    return productSet.products.products;

  return [];
};
