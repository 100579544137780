import { RouteName } from 'routes';
import { AttachmentType } from 'behavior/attachments';
import type { CalculatedProduct } from 'behavior/pages/product';
import type { Product } from './types';

const relatedProductGroups = [
  {
    relationType: '1',
    products: [
      {
        id: '001',
        image: null,
        url: '/product-001',
        routeData: { routeName: RouteName.ProductDetails },
        title: 'Product title 1',
      },
      {
        id: '002',
        image: null,
        url: '/product-002',
        routeData: { routeName: RouteName.ProductDetails },
        title: 'Product title 2',
      },
      {
        id: '003',
        image: null,
        url: '/product-003',
        routeData: { routeName: RouteName.ProductDetails },
        title: 'Product title 3',
      },
    ],
  },
  {
    relationType: '0',
    products: [
      {
        id: '004',
        image: null,
        url: '/product-004',
        routeData: { routeName: RouteName.ProductDetails },
        title: 'Product title 1',
      },
      {
        id: '005',
        image: null,
        url: '/product-005',
        routeData: { routeName: RouteName.ProductDetails },
        title: 'Product title 2',
      },
    ],
  },
];

const specifications = [
  {
    name: 'Specification 1',
    value: 'Value 1',
  },
  {
    name: 'Specification 2',
    value: 'Value 2',
  },
  {
    name: 'Specification 3',
    value: 'Value 3',
  },
];

const attachments = [
  {
    title: 'Attachment 1',
    type: AttachmentType.Url,
    url: 'https://www.default-link.com',
    variant: {
      title: 'Variant id',
    },
  },
  {
    title: 'Attachment 2',
    type: AttachmentType.Url,
    url: 'https://www.default-link.com',
    variant: null,
  },
  {
    title: 'Attachment 3',
    type: AttachmentType.Url,
    url: 'https://www.default-link.com',
    variant: null,
  },
];

const reviews = {
  avg: 4.5,
  total: 10,
  list: [{
    title: 'Review title #10',
    rating: 4,
    date: '2021-11-12T12:13:00.00Z',
    description: 'Review description #10',
  }, {
    title: 'Review title #9',
    rating: 5,
    date: '2021-10-12T17:14:00.00Z',
    description: 'Review description #9',
  }, {
    title: 'Review title #8',
    rating: 5,
    date: '2021-09-12T17:15:00.00Z',
    description: 'Review description #8',
  }, {
    title: 'Review title #7',
    rating: 4,
    date: '2021-08-12T17:16:00.00Z',
    description: 'Review description #7',
  }, {
    title: 'Review title #6',
    rating: 5,
    date: '2021-07-12T17:17:00.00Z',
    description: 'Review description #6',
  }],
};

const description = `
<div>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tincidunt finibus purus, in rhoncus purus facilisis vel. Suspendisse et blandit lacus. Maecenas a sapien non nisi dictum ultricies at vitae lorem. Aenean congue erat erat, vel mattis ligula malesuada venenatis. Aliquam tortor lacus, lobortis nec justo eget, dictum scelerisque nisl. Vivamus gravida faucibus felis ac lacinia. Suspendisse dolor lectus, sollicitudin vitae pretium congue, lacinia in risus. Nullam id eros sed purus tristique commodo. Vestibulum ac massa mollis lorem pulvinar blandit. Aenean elementum metus ut nibh vulputate rutrum. Vestibulum quis nunc elit. Aenean tincidunt laoreet elementum. Quisque viverra, arcu at pretium bibendum, magna nulla vulputate nulla, eget fringilla eros arcu vitae ipsum.
  </p>
  <p>
    Integer quis enim imperdiet lacus mollis rutrum id sed lacus. Cras rutrum ac enim vitae scelerisque. Nullam et justo at quam mattis suscipit. Nulla facilisi. Ut malesuada, dui imperdiet lobortis scelerisque, urna erat laoreet justo, ac egestas ligula metus nec est. Vivamus pulvinar purus at quam pharetra congue ut sed ex. Nunc ac nulla varius, ultricies lacus sit amet, efficitur erat. In pellentesque, erat ac euismod semper, nunc metus malesuada quam, nec finibus massa dolor eget turpis. Vivamus a elementum justo. Vestibulum ut nisi sit amet magna placerat fringilla. Mauris id euismod nunc, ut tempor urna.
  </p>
  <p>
    Sed faucibus, mi non volutpat sagittis, quam ligula imperdiet mauris, vel lobortis nunc risus id turpis. Aliquam erat volutpat. Duis neque ante, maximus sit amet mi nec, consequat dignissim mauris. Nam id tellus id neque sodales fringilla. Sed egestas aliquam blandit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; In massa magna, pellentesque eu neque at, imperdiet congue magna. Fusce consequat justo a molestie ullamcorper. Nulla ornare ex velit, vitae imperdiet urna pulvinar et. Fusce placerat sodales est, sit amet vehicula ex finibus a. Vestibulum ultricies dui sit amet justo tincidunt, at ullamcorper leo ornare.
  </p>
  <p>
    Donec nec hendrerit ante, quis placerat ex. Pellentesque tristique, ex eu finibus efficitur, mi lacus bibendum ex, congue consectetur tellus velit non augue. Mauris vestibulum, leo eget tempor luctus, mauris ligula tristique nulla, vel hendrerit est sem sed odio. Vivamus vitae nulla in metus congue ultricies quis eget eros. Cras id tempor ligula. Nullam sodales est non magna malesuada pretium. Suspendisse odio nisi, laoreet placerat felis id, cursus eleifend ipsum. Vestibulum enim lectus, gravida lobortis mollis in, luctus sed odio. Proin at vulputate diam.
  </p>
  <p>
    Sed dignissim ante vel nunc interdum sagittis. Maecenas pellentesque diam id posuere malesuada. Cras tincidunt ex urna, non lacinia sapien fermentum fringilla. Sed consequat est quis ipsum vulputate, vel dapibus diam sagittis. Aenean id rhoncus magna. Ut hendrerit nibh et tristique consectetur. Mauris nec risus nec urna eleifend tincidunt.
  </p>
</div>
`;

const uoms = [
  {
    defaultQuantity: 1,
    description: 'Liters',
    id: 'LT',
    maximumQuantity: 5,
    minimumQuantity: 0.5,
    quantityStep: 0.5,
  },
  {
    defaultQuantity: null,
    description: 'Box',
    id: 'BOX',
    maximumQuantity: 4,
    minimumQuantity: null,
    quantityStep: 1,
  },
];

const variantComponentGroups = [
  {
    id: 'COLOR',
    name: 'Color:',
    components: [
      { id: 'BLACK', name: 'Black', variants: ['BLACK-S', 'BLACK-M'] },
      { id: 'WHITE', name: 'White', variants: ['WHITE-S', 'WHITE-M'] },
    ],
  },
  {
    id: 'SIZE',
    name: 'Size:',
    components: [
      { id: 'S', name: 'Small', variants: ['BLACK-S', 'WHITE-S'] },
      { id: 'M', name: 'Medium', variants: ['BLACK-M', 'WHITE-M'] },
    ],
  },
];

const variantBaseData = {
  price: 100,
  listPrice: null,
  inventory: 100,
  isOrderable: true,
  bomComponents: null,
};

const variants = [
  { id: 'BLACK-M', ...variantBaseData },
  { id: 'BLACK-S', ...variantBaseData },
  { id: 'WHITE-M', ...variantBaseData },
  { id: 'WHITE-S', ...variantBaseData },
];

export const salesAgreementsStubData = {
  canViewUom: true,
  linesToDisplay: [
    {
      id: '0001',
      discountPercent: 5,
      price: 1000,
      uom: {
        id: 'BOX',
        description: 'Box',
      },
      quantities: {
        remaining: null,
        commitment: null,
      },
      amounts: {
        remaining: null,
      },
    },
  ],
};

const bomComponents = [{
  productId: 'HAT',
  quantity: 1,
  title: 'Hat',
  horizontal: null,
  vertical: null,
  variant: null,
}];

const product: Product & CalculatedProduct = {
  id: 'Product ID',
  title: 'Product title',
  url: null,
  image: null,
  hasVariants: true,
  hasBomComponents: true,
  hasVolumePrices: true,
  price: 500,
  listPrice: 1000,
  inventory: 100,
  isOrderable: true,
  specifications,
  attachments,
  reviews,
  media: null,
  productConfiguratorInfo: {
    isProductConfigurable: false,
  },
  relatedProductGroups,
  uoms,
  uom: { id: uoms[0].id },
  stockLevels: {
    outOfStock: 0,
    lowStock: 5,
    maxStockNumber: null,
  },
  retailOffers: null,
  description,
  variantComponentGroups,
  variants,
  bomComponents,
  productGroup: {
    id: 'test-id',
    url: 'test-url',
  },
};

export default product;
