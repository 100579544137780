import styles from './Header.module.scss';
import { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const UseStickyHeader = ({ showSticky, children }) => {
  const containerRef = useRef();
  const className = showSticky ? styles.sticky : styles.static;
  
  useEffect(() => {
    setTimeout(() => containerRef.current?.classList.toggle(styles.show, showSticky), 0);
  }, [showSticky]);

  return (
    <div 
      ref={containerRef} 
      key={className} 
      className={className} 
      data-scroll-lock-fill-gap={showSticky || null}
    >
      {children}
    </div>
  );
};

UseStickyHeader.propTypes = {
  showSticky: PropTypes.bool,
  children: PropTypes.node,
};

export default memo(UseStickyHeader);
